canvas {
  vertical-align: bottom;
  display: block;
}

#particles-js {
  z-index: -1;
  background: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

/*# sourceMappingURL=index.95b983d2.css.map */
